import "./account.scss";
import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import Navbar from "../landing/header/Navbar";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import moment from "moment";
import Environment from "../utils/environment";
// import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GoogleMapReact from "google-map-react";
import Geocode from "react-geocode";
import MainLoader from "../utils/MainLoader";

const Account = () => {
  const api_url = Environment.base_url2;
  const api_url1 = Environment.base_url;

  const history = useHistory();
  const val = localStorage.getItem("accessToken");
  const userId = localStorage.getItem("userId");

  const [loader, setLoader] = useState(null);
  const [profilePicture, setProfilePicture] = useState(null);
  const [profilePicture2, setProfilePicture2] = useState(null);
  const [addressList, setAddressList] = useState([]);
  const [addressTypeList, setAddressTypeList] = useState([]);
  const [isAddressEdit, setIsAddressEdit] = useState(false);
  const [addressId, setAddressId] = useState("");
  const [select, setSelect] = useState(false);
  console.log(select, "selecttttt");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("Islamabad");
  const [state, setState] = useState("");
  const [nearBy, setNearBy] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [name, setName] = useState("");

  const [mobileNumber, setMobileNumber] = useState("");

  const [addressTypeId, setAddressTypeId] = useState("");
  const [activeState, setActiveState] = useState("");
  const [orderid, setOrderId] = useState("");
  const [receiptImg, setReceiptImg] = useState("");

  const [issuetype, setIssueType] = useState("");
  const [subject, setSubject] = useState("");
  const [showIssue, setShowIssue] = useState(0);
  const [showDetail, setShowDetail] = useState(3);

  const [orderData, setOrderData] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [orderDetailShow, setOrderDetailsShow] = useState(false);
  const [curr, setCurrent] = useState("");
  const [currerror, setCurrError] = useState("");
  const [newwerror, setNewwError] = useState("");
  const [confirmerror, setConfirmError] = useState("");
  const [neww, setNew] = useState("");
  const [confirm, setConfirm] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [currentpasserror, setCurrentPassError] = useState("");
  const [addresserrors, setAddressErrors] = useState("");
  const [cityerrors, setCityErrors] = useState("");
  const [nameerrors, setNameErrors] = useState("");
  const [mobileerrors, setMobileErrors] = useState("");
  const [errororderid, setErrorOrderId] = useState("");
  const [errorissue, setErrorIssue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingg, setIsLoadingg] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const setProfilePic = (evt) => {
    setProfilePicture(evt.target.files[0]);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showlocation, setShowlocation] = useState(false);
  const Marker = ({ lat, lng }) => (
    <div className="marker" style={{ width: "0px", height: "0px" }}>
      <span role="img" aria-label="marker" style={{ fontSize: "40px" }}>
        <img src="\assets\location.png"></img>
      </span>
    </div>
  );

  const [locationAddress, setLocationAddress] = useState("");
  const [location, setLocation] = useState({
    center: {
      lat: 33.6844,
      lng: 73.0479,
    },
    zoom: 13,
  });
  const [clickedLocation, setClickedLocation] = useState(null);
  const handleCloselocation = () => {
    setIsAddressEdit(false);
    setShowlocation(false);
    setNameErrors("");
    setCityErrors("");
    setMobileErrors("");
    setAddressErrors("");
    setAddressId("");
    setAddress("");
    setNearBy("");
    setName("");
    setMobileNumber("");
    setPostalCode("");
    setState("");
    setActiveState("");
  };
  const handleShowlocation = () => setShowlocation(true);
  const [showdelete, setShowdelete] = useState(false);

  const handleClosedelete = () => setShowdelete(false);
  const handleShowdelete = () => setShowdelete(true);

  const [showreciept, setShowReciept] = useState(false);
  const [uploadreciept, setUploadReciept] = useState(false);
  const handleCloseRec = () => setShowReciept(false);
  const handleShowRec = () => setShowReciept(true);

  const [getuploadId, setgetuploadId] = useState("");

  const showUploadModal = (id) => {
    setgetuploadId(id);
    if (uploadreciept) {
      setUploadReciept(false);
    } else {
      setUploadReciept(true);
    }
  };

  const handleMapClick = (event) => {
    setAddressErrors("");
    setSelect(true);
    setLocation({
      center: {
        lat: event.lat,
        lng: event.lng,
      },
      zoom: 15,
    });
    const clickedLat = event.lat;
    const clickedLng = event.lng;
    setClickedLocation({ lat: clickedLat, lng: clickedLng });
    Geocode.setApiKey("AIzaSyAEGTG-K3yjuM5jaOVjCexGc6LdwgZwbB0");

    Geocode.fromLatLng(event.lat, event.lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        setAddress(address);
      },
      (error) => {
        console.error(error);
      }
    );
    // Do something with the location data, e.g. update state
  };
  // useEffect(() => {
  //   if (issue) {
  //     setOrderId(issue?.orderId);
  //     setIssueType(issue?.issueType);
  //     setSubject(issue?.subject);
  //   }
  // }, [issue]);

  const [issue, setIssue] = useState([]);
  function Editissue(id) {
    axios
      .get(`${Environment.base_url2}/users/my-issue/${id}`, {
        headers: {
          Authorization: "Bearer " + val,
        },
      })
      .then((res) => {
        setIssue(res?.data?.data);
      })
      .catch((err) => { });
  }
  // issueetail show
  // useEffect(() => {
  //   if (!val) {
  //     history.push("/");
  //   } else {
  //     Editissue();
  //   }
  // }, []);
  // post issue

  const Addissue = async (e) => {
    if (orderid.length === 0) {
      setErrorOrderId("Enter Order Id");
    }
    if (issuetype.length === 0) {
      setErrorIssue("Enter Subject");
    } else {
      const config = {
        method: "post",
        url: `${Environment.base_url}/supports`,
        data: {
          orderId: orderid,
          subject: subject,
          issueType: issuetype,
        },
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then((res) => {
          handleClose();
          toast.success(res?.data?.message);
          setLoader(false);
        })
        .catch((err) => {
          if (err?.response?.statusCode == 501) {
            history.push("/");
          } else {
            // toast.error(err?.response?.data?.message, {
            //   position: "bottom-left",
            //   autoClose: 2000,
            // });
          }
          setLoader(false);
        });
    }
  };
  // issue list
  const [issueList, setIssueList] = useState([]);

  const getIssuesList = async () => {
    // setIsLoading(true);
    setShowDetail(3);
    setShowIssue(null);
    setIsLoading(true);
    await axios
      .get(`${Environment.base_url2}/users/my-issues?offset=1&&limit=5`, {
        headers: {
          Authorization: "Bearer " + val,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setIssueList(res?.data?.data?.myIssues);
      })
      .catch((err) => { });
    setIsLoading(false);
  };
  useEffect(() => {
    if (!val) {
      history.push("/login");
    } else {
      setIsLoading(false);
      getIssuesList();
    }
  }, []);

  const getAddressList = async () => {
    setIsLoading(true);
    setLoader(true);
    await axios
      .get(`${api_url}/address`, {
        headers: {
          Authorization: "Bearer " + val,
        },
      })
      .then((res) => {
        setAddressList(res?.data?.data);
        setLoader(false);
        setIsLoading(false);
      })
      .catch((err) => {
        setLoader(false);
        setIsLoading(false);
      });
  };

  const deleteAddress = async () => {
    setLoader(true);
    const config = {
      method: "delete",
      url: `${api_url}/address/${addressId}`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        getAddressList();
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else {
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
  };

  const getAddressTypeList = async () => {
    setLoader(true);
    const config = {
      method: "get",
      url: `${api_url}/address/types`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        const newAddress = {
          id: res?.data?.addressId,
          name: name,
          // Other properties of the address
        };
        setAddressTypeList(resData);
        setAddressTypeId(resData[0]?.id);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          history.push("/");
        } else {
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
  };

  const getAddressDetails = async (id) => {
    setLoader(true);

    if (id) {
      const config = {
        method: "get",
        url: `${api_url}/address/${id}`,
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then((res) => {
          const resData = res?.data?.data;
          setAddress(resData?.addressDetails?.address);
          setCity(resData?.addressDetails?.city);
          setNearBy(resData?.addressDetails?.nearByPlace);
          setPostalCode(resData?.addressDetails?.postalCode);
          setMobileNumber(resData?.addressDetails?.contactNo);
          setName(resData?.addressDetails?.name);
          setState(resData?.addressDetails?.state);
          for (let i = 0; i < resData?.addressTypes.length; i++) {
            if (resData?.addressTypes[i]?.userAddress != null) {
              setActiveState(resData?.addressTypes[i]?.addressTypeId);
            }
          }
          setLoader(false);
        })
        .catch((err) => {
          if (err?.response?.status == 501) {
            history.push("/");
          } else {
            toast.error(err?.response?.data?.message, {
              position: "top-right",
              autoClose: 2000,
            });
          }
          setLoader(false);
        });
    }
  };
  const [suggestions, setSuggestions] = useState([]);

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    setAddress(searchValue);

    if (!searchValue) {
      setSuggestions([]);
      return;
    }

    if (window.google && window.google.maps) {
      const autocompleteService =
        new window.google.maps.places.AutocompleteService();
      autocompleteService.getPlacePredictions(
        {
          input: searchValue,
          types: ["address"],
          componentRestrictions: {
            country: "pk",
          },
        },
        (predictions, status) => {
          console.log("preds: ", predictions, status);
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            // Filter suggestions for Islamabad and Rawalpindi
            const filteredSuggestions = predictions.filter(
              (suggestion) =>
                suggestion.description.includes("Islamabad") ||
                suggestion.description.includes("Rawalpindi")
            );
            setSuggestions(filteredSuggestions);
          } else {
            setSuggestions([]);
          }
        }
      );
    }
  };

  const handleSuggestionClick = (suggestion) => {
    if (suggestion) {
      const selectedPlaceId = suggestion?.place_id;
      const placesService = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );

      placesService.getDetails(
        { placeId: selectedPlaceId },
        (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            console.log("Place details:", place);
            const location = place.geometry.location;
            console.log("Location:", location.lat(), location.lng());
            setLocation({
              center: {
                lat: location.lat(),
                lng: location.lng(),
              },
              zoom: 15,
            });
          } else {
            console.error("Error fetching place details:", status);
          }
        }
      );
    }
    setAddress(suggestion.description);
    setSuggestions([]);
  };

  const validateaddress = () => {
    if (address.length === 0) {
      setAddressErrors("Address is Required");
    }
    if (setSelect === false) {
      setAddressErrors("Select Address from suggestion");
    }
    if (city.length === 0) {
      setCityErrors("City is Required");
    }
    if (name.length === 0) {
      setNameErrors("Name is Required");
    }
    if (mobileNumber.length === 0) {
      setMobileErrors("Mobile number is Required");
    }
    if (mobileNumber.length <= 10) {
      setMobileErrors("Mobile number is invalid");
    }
  };

  const addNewAddress = async () => {
    validateaddress();
    if (mobileNumber?.length === 11 && select === true) {
      handleCloselocation();
      setIsLoading(true);
      window.scrollTo(0, 0);
      const config = {
        method: "post",
        url: api_url + "/address",
        data: {
          address: address,
          city: city,
          ...(state && { state: state }),
          ...(nearBy && { nearByPlace: nearBy }),
          ...(postalCode && { postalCode: postalCode }),
          name: name,
          contactNo: mobileNumber,
          latitude: location?.center?.lat,
          longitude: location?.center.lng,
          addressTypeId: addressTypeId,
        },
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then((res) => {
          getAddressList();
          toast.success(res?.data?.message);
          setLoader(false);
          setAddress("");
          setCity("Islamabad");
          setName("");
          setMobileNumber("");
          setNearBy("");
        })
        .catch((err) => {
          if (err?.response?.statusCode == 501) {
            history.push("/");
          } else {
            // toast.error(err?.response?.data?.message, {
            //   position: "bottom-left",
            //   autoClose: 2000,
            // });
          }
          setIsLoading(false);
        });
    } else {
      // setAddressErrors("Select Address from suggestion");
    }
  };

  const editAddress = async () => {
    validateaddress();
    if (mobileNumber?.length === 11) {
      handleCloselocation();
      setIsLoading(true);
      window.scrollTo(0, 0);
      const config = {
        method: "patch",
        url: api_url + "/address/" + addressId,
        data: {
          address: address,
          city: city,
          ...(state && { state: state }),
          ...(nearBy && { nearByPlace: nearBy }),
          ...(postalCode && { postalCode: postalCode }),
          name: name,
          contactNo: mobileNumber,
          latitude: location?.center?.lat,
          longitude: location?.center.lng,
          addressTypeId: addressTypeId,
        },
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then((res) => {
          handleCloselocation();
          toast.success(res?.data?.message);
          getAddressList();
          setIsLoading(false);
        })
        .catch((err) => {
          if (err?.response?.statusCode == 501) {
            history.push("/");
          } else {
            toast.error(err?.response?.data?.message, {
              position: "bottom-left",
              autoClose: 2000,
            });
          }
          setIsLoading(false);
        });
    }
  };

  const getOrderData = async () => {
    setIsLoading(true);
    setOrderData([]);
    setLoader(true);
    const config = {
      method: "get",
      url: api_url + "/users/my-orders?limit=40" + "&offset=1",
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const resData = res?.data?.data;
        setOrderData(resData?.myOrders);
        setLoader(false);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          localStorage.removeItem("accessToken");
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
          // FetchRefreshToken();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
        setIsLoading(false);
      });
  };
  const getOrderDetails = async (id) => {
    setLoader(true);
    const config = {
      method: "get",
      url: api_url + "/users/my-order/" + id,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const resData = res?.data;

        setOrderDetails(resData?.data);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.status == 501) {
          localStorage.removeItem("accessToken");
          history.push("/");
        } else if (err?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/");
          // FetchRefreshToken();
        }
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
  };

  // user pic
  // rider image
  const [image, setImage] = useState(null);
  const [imageUp, setImageUp] = useState(null);

  const onImageChange = (event) => {
    setImageUp(event.target.files[0]);
    setImage(event.target.files[0]);
  };

  // User Details
  const [userData, setUserData] = useState();
  const [adminName, setAdminName] = useState("");
  const [adminNumber, setAdminNumber] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [gender, setGender] = useState("");

  const getUserDetail = async () => {
    setLoader(true);
    const config = {
      method: "get",
      url: `${api_url}/users/${userId}`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    await axios(config)
      .then((res) => {
        const responseData = res?.data?.data;
        setUserData(responseData);
        setAdminName(responseData?.name);
        setAdminEmail(responseData?.email);
        setAdminNumber(responseData?.contactNo);
        setProfilePicture2(responseData?.imageUrl);
        setGender(responseData?.gender);
        setLoader(false);
      })
      .catch((err) => {
        if (err?.response?.statusCode == 501) {
          history.push("/login");
        } else {
          toast.error(err?.response?.data?.msg, {
            position: "top-right",
            autoClose: 2000,
          });
        }
        setLoader(false);
      });
  };

  useEffect(() => {
    if (!val) {
      history.push("/login");
    } else {
      getUserDetail();
      getOrderData();
      window.scrollTo(0, 0);
    }
  }, []);

  const [adminnameerror, setAdminNameError] = useState("");
  const [adminnumbererror, setAdminNumberError] = useState("");
  const [adminemailerror, setAdminEmailError] = useState("");
  const validateaccountdetail = () => {
    if (adminName.length === 0) {
      setAdminNameError("Name is Required");
    }
    if (!adminNumber) {
      setAdminNumberError("Phone number is Required");
    } else if (adminNumber.length != 11) {
      setAdminNumberError("Phone number is invalid");
    }
    if (adminEmail.length === 0) {
      setAdminEmailError("Email is Required");
    } else if (!isValidEmail(adminEmail)) {
      setAdminEmailError("Email is invalid");
    }
  };
  const SaveChanges = async () => {
    // validateaccountdetail();
    setIsLoading(true);
    if (!adminName) {
      setAdminNameError("Name is Required");
      setIsLoading(false);
      return;
    }
    if (!adminNumber) {
      setAdminNumberError("Phone number is Required");
      setIsLoading(false);
      return;
    }
    if (adminNumber.length != 11) {
      setAdminNumberError("Phone number is invalid");
      setIsLoading(false);
      return;
    }
    if (!adminEmail) {
      setAdminEmailError("Email is Required");
      setIsLoading(false);
      return;
    } else if (!isValidEmail(adminEmail)) {
      setAdminEmailError("Email is invalid");
      setIsLoading(false);
      return;
    }
    if (isValidEmail(adminEmail)) {
      const formData = new FormData();
      if (adminName) {
        formData.append("name", adminName);
      }
      if (adminNumber) {
        formData.append("contactNo", adminNumber);
      }
      if (adminEmail) {
        formData.append("email", adminEmail);
      }
      if (profilePicture) {
        formData.append("profileImage", profilePicture);
      }
      if (gender) {
        formData.append("gender", gender);
      }
      const config = {
        method: "patch",
        url: api_url + "/users/profile",
        data: formData,
        headers: {
          Authorization: "Bearer " + val,
        },
      };
      await axios(config)
        .then((res) => {
          toast.success(res?.data?.message);
          // setAdminName("");
          setIsLoading(false);
          history.push("/");
        })
        .catch((err) => {
          setAdminName("");
          if (err?.response?.statusCode == 501) {
            localStorage.clear();
            history.push("/");
          } else {
            toast.error(err?.response?.data?.message, {
              position: "top-right",
              autoClose: 2000,
            });
          }
          setIsLoading(false);
        });
    } else {
      // setAdminEmailError("Email is invalid");
    }
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email, adminEmail);
  }
  const ChangePassword = async () => {
    setPasswordError("");
    validatepassword();
    if (!passError) {
      if (neww != confirm) {
        setPasswordError("New & Confirm Password must be same");
      } else {
        if (checkPassword(neww)) {
          setPasswordError("");
          setLoader(true);
          const data = {
            currentPassword: curr,
            newPassword: neww,
            confirmPassword: confirm,
          };
          await axios
            .patch(`${api_url}/users/password`, data, {
              headers: {
                Authorization: "Bearer " + val,
              },
            })
            .then((res) => {
              toast.success(res?.data?.message);
              handleClose();
              setLoader(false);
            })
            .catch((err) => {
              if (err?.response?.statusCode == 501) {
                // localStorage.clear();
                history.push("/");
              } else if (err?.response?.data?.msg) {
                toast.error(err?.response?.data?.msg, {
                  position: "top-right",
                  autoClose: 2000,
                });
              } else {
                // toast.error(err?.response?.data?.message, {
                //   position: "top-right",
                //   autoClose: 2000,
                // });
                setCurrentPassError("Current Password is Incorrect");
              }
              setLoader(false);
            });
        } else {
          setPasswordError(
            "Password should be 8-16 characters, should contain atleast 1 special character (/ $ @ ), atleast one upper and one lower case letter and atleast one number"
          );
        }
      }
    }
  };

  const validatepassword = () => {
    if (!curr) {
      setCurrError("Enter Current Password");
    }
    if (!neww) {
      setNewwError("New Password is Required");
    }
    if (!confirm) {
      setConfirmError("Confirm Your Password");
    }
    if (!neww || neww === "") {
      passError = true;
    } else {
      passError = false;
    }
  };

  function checkPassword(str) {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(str);
  }
  let passError = false;
  const setCurrPass = (e) => {
    setCurrError("");
    setCurrent(e.target.value);
  };
  const setNewPass = (e) => {
    setNew(e.target.value);
    setNewwError("");
  };
  const setConPass = (e) => {
    setConfirm(e.target.value);
    setConfirmError("");
  };

  const handleLogout = (e) => {
    setIsLoadingg(true);
    e.preventDefault();
    // setLoader(true);

    var config = {
      method: "delete",
      url: `${api_url}/users-auth/logout`,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + val,
      },
    };

    axios(config)
      .then(function (response) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("cartItems");
        localStorage.removeItem("discountAmount");
        localStorage.removeItem("couponCode");
        localStorage.removeItem("criteria");
        history.push("/");
        // window.location.reload();
        setIsLoadingg(false);
        // setLoader(false);
      })
      .catch(function (error) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("cartItems");
        localStorage.removeItem("discountAmount");
        localStorage.removeItem("couponCode");
        localStorage.removeItem("criteria");
        history.push("/");
        // window.location.reload();
        setIsLoadingg(false);
        // setLoader(false);
      });
  };

  // upload image url

  const [uploadimage, setUploadImage] = useState("");
  const UploadImageChange = (e) => {
    setUploadImage(e.target.files[0]);
  };

  const placeOrder = async () => {
    const formData = new FormData();
    formData.append("transactionReceiptImage", uploadimage);
    setLoader(true);
    if (uploadimage) {
      let config = {
        method: "patch",
        url: api_url1 + `/orders/${getuploadId}/transaction-receipt`,
        data: formData,
        headers: {
          Authorization: "Bearer " + val,
        },
      };

      await axios(config)
        .then((res) => {
          toast.success(res?.data?.message);
          setLoader(false);
          showUploadModal();
          setUploadImage("");
          getOrderData();

          // getOrderDetails()
          // history.push("/cartsuccess");
        })
        .catch((err) => {
          if (err?.response?.statusCode == 501) {
            history.push("/");
          } else {
            toast.error(err?.response?.data?.message, {
              position: "bottom-left",
              autoClose: 2000,
            });
          }
          setLoader(false);
        });
    } else {
      toast.warning("please upload image first");
    }
  };

  return (
    <>
    {isLoading  && <MainLoader/>}
    
      {/* <Navbar /> */}
      <section className="sec-main gcgsgcgscccscxcgcsc">
        <div className="container-fluid">
          <div className="breadcrumbb_main ">
            <div className="row">
              <div className="col-11 m-auto">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <Link to="/">
                      <li class="breadcrumb-item mr-2 pr-2">
                        <a>Home /</a>
                      </li>
                    </Link>
                    <li class="breadcrumb-item active" aria-current="page">
                      My account
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
          {isLoadingg ? (
            <div className="loader-image-logout">
              <img src="assets\btn-icons\2nd_v2.gif" alt="Loading..." />
            </div>
          ) : (
            <div className="row">
              <div className="col-12 ">
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-11 col-sm-11 ">
                    <nav>
                      <div
                        class="flex-column  left-up"
                        id="nav-tab"
                        role="tablist"
                      >
                        {/* <button
                        class="accountnew-tabs  active"
                        id="nav-Dashboard-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-Dashboard"
                        type="button"
                        role="tab"
                        aria-controls="nav-Dashboard"
                        aria-selected="true"
                      >
                        {" "}
                        <p className=" twoooo">Dashboard</p>
                      </button>
                      // <hr></hr> */}
                        <button
                          className=" active accountnew-tabs "
                          id="nav-Orders-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-Orders"
                          type="button"
                          role="tab"
                          aria-controls="nav-Orders"
                          aria-selected="false"
                          onClick={() => {
                            getOrderData();
                            setOrderDetailsShow(false);
                            setIsLoading(true);
                          }}
                        >
                          <p className=" twoooo">
                            <img
                              src="\Assests\document-text.svg"
                              alt="img"
                              className="img-fluid "
                            />
                            My Orders</p>
                        </button>
                        {/* <hr></hr> */}
                        {/* <button
                          className="accountnew-tabs   "
                          id="nav-Issues-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-Issues"
                          type="button"
                          role="tab"
                          aria-controls="nav-Issues"
                          aria-selected="false"
                        >
                          <p onClick={getIssuesList} className="twoooo">
                            <img
                              src="\Assests\Issues.svg"
                              alt="img"
                              className="img-fluid "
                            />
                            My Wishlist
                          </p>
                        </button> */}
                        {/* <hr></hr> */}

                        {/* <button
                          className="accountnew-tabs   "
                          id="nav-Issues-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-Issues"
                          type="button"
                          role="tab"
                          aria-controls="nav-Issues"
                          aria-selected="false"
                        >
                          <p className="twoooo">
                            <img
                              src="\Assests\info-circle.svg"
                              alt="img"
                              className="img-fluid "
                            />
                            My Issues
                          </p>
                        </button> */}
                        {/* <hr></hr> */}
                        <button
                          className="accountnew-tabs   "
                          id="nav-Addresses-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-Addresses"
                          type="button"
                          role="tab"
                          aria-controls="nav-Addresses"
                          aria-selected="true"
                        >
                          <p
                            className=" twoooo"
                            onClick={() => {
                              getAddressList();
                            }}
                          >
                            {" "}
                            <img
                              src="\Assests\locationss.svg"
                              alt="img"
                              className="img-fluid "
                            />
                            Addresses
                          </p>
                        </button>
                        {/* <hr></hr> */}
                        <button
                          className="accountnew-tabs   "
                          id="nav-Details-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-Details"
                          type="button"
                          role="tab"
                          aria-controls="nav-Details"
                          aria-selected="true"
                        >
                          {" "}
                          <p
                            onClick={() => getUserDetail()}
                            className=" twoooo"
                          >
                            <img
                              src="\Assests\framexxxx.svg"
                              alt="img"
                              className="img-fluid "
                            />
                            Account Details
                          </p>
                        </button>
                        <button className="accountnew-tabs" onClick={handleLogout}>
                          <img
                            src="\Assests\logout.svg"
                            alt="img"
                            className="img-fluid "
                          />
                          Logout
                        </button>
                        <div className="leftdown">

                        </div>
                        {/* <hr></hr> */}
                      </div>

                    </nav>
                  </div>

                  <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 m-auto">
                    <div className="tab-content  " id="nav-tabContent">
                      {/* <div
                      className="tab-pane fade active show"
                      id="nav-Dashboard"
                      role="tabpanel"
                      aria-labelledby="nav-Dashboard-tab"
                    >
                      <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-auto  ">
                          <div className="right-main">
                            <div>
                              <p className="pa-1">
                                Hello<span className="span1"> ub1894497</span>
                                (not<span className="span1">
                                  ub1894497?
                                </span>{" "}
                                <button
                                  className="span2 log___outtt__btn"
                                  onClick={() => {
                                    localStorage.removeItem("accessToken");
                                    history.push("/login");
                                  }}
                                >
                                  Log out
                                </button>{" "}
                              </p>
                              <p className="pa-2">
                                From your account dashboard you can view your
                                <span className="span1">
                                  {" "}
                                  recent orders,
                                </span>{" "}
                                manage your
                                <span className="span1">
                                  {" "}
                                  shipping and billing addresses,
                                </span>{" "}
                                and
                                <span className="span1">
                                  {" "}
                                  edit your password and account details.
                                </span>
                              </p>
                              <div className="blue_div">
                                <div className="d-flex">
                                  <img
                                    src="\assets\tick.svg"
                                    className=""
                                  ></img>
                                  <p className="order_para ">
                                    No order has been made yet.
                                  </p>
                                </div>
                                <div>
                                  <p className="browser">
                                    <u>Browse products</u>
                                  </p>
                                </div>
                              </div>

                              <div>
                                <p className="pa-2">
                                  The following addresses will be used on the
                                  checkout page by default.
                                </p>
                              </div>

                              <div className="main_billing">
                                <div className="main_billingedit">
                                  <h4 className="address">Billing address</h4>
                                  <p className="edit">Edit</p>
                                </div>

                                <ul className="">
                                  <li className="names_list">John Doe</li>
                                  <li className="names_list">Quecko Inc.</li>
                                  <li className="names_list">
                                    Plot 31, Faqir Aipee Road,Sector I-11/3
                                    Islamabad
                                  </li>
                                  <li className="names_list">Islamabad</li>
                                  <li className="names_list">Punjab</li>
                                  <li className="names_list">44000</li>
                                  <li className="names_list">Pakistan</li>
                                </ul>
                              </div>
                              <div className="main_billing">
                                <div className="main_billingedit">
                                  <h4 className="address">Shipping address</h4>
                                  <p className="edit">Edit</p>
                                </div>

                                <ul className="">
                                  <li className="names_list">John Doe</li>
                                  <li className="names_list">Quecko Inc.</li>
                                  <li className="names_list">
                                    Plot 31, Faqir Aipee Road,Sector I-11/3
                                    Islamabad
                                  </li>
                                  <li className="names_list">Islamabad</li>
                                  <li className="names_list">Punjab</li>
                                  <li className="names_list">44000</li>
                                  <li className="names_list">Pakistan</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                      <div
                        class="tab-pane fade active show"
                        id="nav-Orders"
                        role="tabpanel"
                        aria-labelledby="nav-Orders-tab"
                      >
                        <div className="row">
                          <div className="col-12  p-0  ">
                            {orderDetailShow ? (
                              <>
                                <div className="col-12 detail-section">
                                  <div className="up-main">
                                    <div className="df-main">
                                      <div>
                                        <p>Order number</p>
                                      </div>
                                      <div>
                                        <p>{orderDetails?.id}</p>
                                      </div>
                                    </div>
                                    <hr className="hr-x"></hr>

                                    <div className="df-main">
                                      <div>
                                        <p>Address</p>
                                      </div>
                                      <div>
                                        <p>{orderDetails?.address?.address}</p>
                                      </div>
                                    </div>
                                    <hr className="hr-x"></hr>

                                    <div className="df-main">
                                      <div>
                                        <p>Payment method</p>
                                      </div>
                                      <div>
                                        {/* <p>Cash on Delivery</p> */}

                                        <p>
                                          {orderDetails?.paymentType?.value}
                                          {orderDetails?.paymentType?.value ===
                                            "Bank Transfer" &&
                                            orderDetails?.transactionReceiptImageUrl ? (
                                            <img
                                              onClick={() => {
                                                handleShowRec();
                                                setReceiptImg(
                                                  orderDetails?.transactionReceiptImageUrl
                                                );
                                              }}
                                              src="\Assests\view-icon.svg"
                                              alt="img"
                                              className="img-fluid ml-2"
                                            />
                                          ) : orderDetails?.paymentType
                                            ?.value === "JazzCash" &&
                                            orderDetails?.transactionReceiptImageUrl ? (
                                            <img
                                              onClick={() => {
                                                handleShowRec();
                                                setReceiptImg(
                                                  orderDetails?.transactionReceiptImageUrl
                                                );
                                              }}
                                              src="\Assests\view-icon.svg"
                                              alt="img"
                                              className="img-fluid ml-2"
                                            />
                                          ) : orderDetails?.paymentType
                                            ?.value === "Easypaisa" &&
                                            orderDetails?.transactionReceiptImageUrl ? (
                                            <img
                                              onClick={() => {
                                                handleShowRec();
                                                setReceiptImg(
                                                  orderDetails?.transactionReceiptImageUrl
                                                );
                                              }}
                                              src="\Assests\view-icon.svg"
                                              alt="img"
                                              className="img-fluid ml-2"
                                            />
                                          ) : orderDetails?.paymentType
                                            ?.value === "Cash On Delivery" ? (
                                            ""
                                          ) : (
                                            <img
                                              onClick={() =>
                                                showUploadModal(
                                                  orderDetails?.id
                                                )
                                              }
                                              src="\Assests\Uploadcart.svg"
                                              alt="img"
                                              className="img-fluid ml-2"
                                            />
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  {/* table */}
                                  <div className="div-tx table-responsive">
                                    <table className="table t-main1">
                                      <thead>
                                        <tr>
                                          <th className="th-1" scope="col">
                                            Products
                                          </th>
                                          <th className="th-1" scope="col">
                                            Price
                                          </th>
                                          <th className="th-1" scope="col">
                                            Total
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {orderDetails?.orderLines?.length >
                                          0 ? (
                                          orderDetails?.orderLines?.map(
                                            (item) => {
                                              return (
                                                <>
                                                  <tr>
                                                    <td
                                                      className="tr-1"
                                                      scope="row"
                                                    >
                                                      <div className="d-flex">
                                                        <div>
                                                          <img
                                                            className="product-image"
                                                            src={
                                                              item?.product
                                                                ?.imageUrl
                                                            }
                                                          ></img>
                                                        </div>
                                                        <div>
                                                          <p className="pr-1">
                                                            {
                                                              item?.product
                                                                ?.name
                                                            }
                                                          </p>
                                                          <p className="pr-2">
                                                            {
                                                              item?.product
                                                                ?.category?.name
                                                            }
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td className="tr-1 pr-e">
                                                      Rs. {item?.price}
                                                    </td>
                                                    <td className="tr-1 pr-e">
                                                      Rs. {item?.price}
                                                    </td>
                                                  </tr>
                                                </>
                                              );
                                            }
                                          )
                                        ) : loader ? (
                                          <>
                                            {/* <div className="text-center py-4">
                                            {
                                              <Spinner
                                                animation="border"
                                                variant="info"
                                              />
                                            }
                                          </div> */}
                                          </>
                                        ) : (
                                          <h5 className="p-3"></h5>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>

                                  {/* table end */}

                                  <div className="up-main mt-4">
                                    <div className="df-main">
                                      <div>
                                        <p>Net amount</p>
                                      </div>
                                      <div>
                                        <p>
                                          Rs.{" "}
                                          {Math.round(
                                            orderDetails?.discount
                                              ? orderDetails?.total -
                                              orderDetails?.discount
                                                ?.discountAmount
                                              : orderDetails?.total
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                    <hr className="hr-x"></hr>

                                    <div className="df-main">
                                      <div>
                                        <p>Delivery fee</p>
                                      </div>
                                      <div>
                                        <p>
                                          Rs.{" "}
                                          {Math.round(
                                            orderDetails?.deliveryFee
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                    <hr className="hr-x"></hr>

                                    <div className="df-main">
                                      <div>
                                        <p className="to-1">Total</p>
                                      </div>
                                      <div>
                                        <p className="to-1">
                                          Rs.{" "}
                                          {Math.round(
                                            orderDetails?.discount
                                              ? orderDetails?.deliveryFee +
                                              orderDetails?.total -
                                              orderDetails?.discount
                                                ?.discountAmount
                                              : orderDetails?.deliveryFee +
                                              orderDetails?.total
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <button className="btnReport_issue">
                                    Report an Issue
                                  </button> */}
                                </div>
                              </>
                            ) : null}
                            <div className="bl-main">
                              <div className="d-flex">
                                <img src="\assets\tick.svg"></img>
                                <p className="pe-1">
                                  No order has been made yet.
                                </p>
                              </div>
                              <div>
                                <p className="pe-2">
                                  <u>Browse products</u>
                                </p>
                              </div>
                            </div>
                            {isLoading ? (
                              <div className="loader-image-myaccounts">
                                <img
                                  src="assets\btn-icons\2nd_v2.gif"
                                  alt="Loading..."
                                />
                              </div>
                            ) : (
                              <div>
                                {!orderDetailShow ? (
                                  <div className="di-main table-responsive">
                                    {orderData?.length > 0 ? (
                                      <table className="table t-mainx">
                                        <thead className="head">
                                          <tr>
                                            <th className="th-1" scope="col">
                                              Date
                                            </th>
                                            <th className="th-1" scope="col">
                                              Payment Type
                                            </th>
                                            <th className="th-1" scope="col">
                                              Order Number
                                            </th>
                                            <th className="th-1" scope="col">
                                              Total
                                            </th>
                                            <th className="th-1" scope="col">
                                              Detail
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {orderData?.map((item) => {
                                            return (
                                              <tr key={item?.id}>
                                                <th
                                                  className="tr-1"
                                                  scope="row"
                                                >
                                                  {moment(
                                                    item?.createdAt
                                                  ).format("DD-MMM-YYYY")}
                                                </th>
                                                <td className="tr-1">
                                                  {item?.paymentType?.value}
                                                  {item?.paymentType?.value ===
                                                    "Bank Transfer" &&
                                                    item?.transactionReceiptImageUrl ? (
                                                    <img
                                                      onClick={() => {
                                                        handleShowRec();
                                                        setReceiptImg(
                                                          item?.transactionReceiptImageUrl
                                                        );
                                                      }}
                                                      src="\Assests\view-icon.svg"
                                                      alt="img"
                                                      className="img-fluid ml-2"
                                                    />
                                                  ) : item?.paymentType
                                                    ?.value === "JazzCash" &&
                                                    item?.transactionReceiptImageUrl ? (
                                                    <img
                                                      onClick={() => {
                                                        handleShowRec();
                                                        setReceiptImg(
                                                          item?.transactionReceiptImageUrl
                                                        );
                                                      }}
                                                      src="\Assests\view-icon.svg"
                                                      alt="img"
                                                      className="img-fluid ml-2"
                                                    />
                                                  ) : item?.paymentType
                                                    ?.value === "Easypaisa" &&
                                                    item?.transactionReceiptImageUrl ? (
                                                    <img
                                                      onClick={() => {
                                                        handleShowRec();
                                                        setReceiptImg(
                                                          item?.transactionReceiptImageUrl
                                                        );
                                                      }}
                                                      src="\Assests\view-icon.svg"
                                                      alt="img"
                                                      className="img-fluid ml-2"
                                                    />
                                                  ) : item?.paymentType
                                                    ?.value ===
                                                    "Cash On Delivery" ? (
                                                    ""
                                                  ) : (
                                                    <img
                                                      onClick={() =>
                                                        showUploadModal(
                                                          item?.id
                                                        )
                                                      }
                                                      src="\Assests\Uploadcart.svg"
                                                      alt="img"
                                                      className="img-fluid ml-2"
                                                    />
                                                  )}
                                                </td>
                                                <td className="tr-1">
                                                  {item?.id}
                                                </td>
                                                <td className="tr-1">
                                                  Rs.
                                                  {Math.round(
                                                    item?.discount
                                                      ? item?.total +
                                                      item?.deliveryFee -
                                                      item?.discount
                                                        ?.discountAmount
                                                      : item?.total +
                                                      item?.deliveryFee
                                                  )}
                                                </td>
                                                <td className="tr-1">
                                                  <button
                                                    className="btn_detail"
                                                    onClick={() => {
                                                      setOrderId(item?.id);
                                                      setOrderDetailsShow(true);
                                                      setReceiptImg(
                                                        item?.transactionReceiptImageUrl
                                                      );
                                                      getOrderDetails(item?.id);
                                                    }}
                                                  >
                                                    Detail
                                                  </button>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    ) : loader ? (
                                      <div className="text-center py-4">
                                        {/* <Spinner animation="border" variant="info" /> */}
                                      </div>
                                    ) : (
                                      <div className="blue_div1">
                                        <div className="d-flex">
                                          <img
                                            src="\assets\tick.svg"
                                            className=""
                                            alt="tick"
                                          />
                                          <p className="order_para">
                                            No order has been made yet.
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ) : null}
                              </div>
                            )}
                            {/* mobile design orders */}
                            {!orderDetailShow ? (
                              <div>
                                {orderData?.length > 0 ? (
                                  orderData?.map((item) => {
                                    return (
                                      <>
                                        <div className="mobile-orders-main">
                                          <div className="order-detail">
                                            <p className="order-date">Date:</p>
                                            <p className="order-date">
                                              {" "}
                                              {moment(item?.createdAt).format(
                                                "DD-MMM-YYYY"
                                              )}
                                            </p>
                                          </div>
                                          <div className="order-detail">
                                            <p className="order-date">
                                              Order ID:
                                            </p>
                                            <p className="order-date">
                                              {item?.id}
                                            </p>
                                          </div>
                                          <div className="order-detail">
                                            <p className="order-date">
                                              Total Price:
                                            </p>
                                            <p className="order-date">
                                              {" "}
                                              {Math.round(
                                                    item?.discount
                                                      ? item?.total +
                                                      item?.deliveryFee -
                                                      item?.discount
                                                        ?.discountAmount
                                                      : item?.total +
                                                      item?.deliveryFee
                                                  )}
                                            </p>
                                          </div>
                                          <div>
                                            <button
                                              onClick={() => {
                                                setOrderId(item?.id);
                                                setOrderDetailsShow(true);
                                                setReceiptImg(
                                                  item?.transactionReceiptImageUrl
                                                );
                                                getOrderDetails(item?.id);
                                              }}
                                              className="detail-btn-orders"
                                            >
                                              DETAIL
                                            </button>
                                          </div>
                                          <hr className="orders-hr"></hr>
                                        </div>
                                      </>
                                    );
                                  })
                                ) : loader ? (
                                  <>
                                    <div className="text-center py-4 left-m">
                                      {/* {
                                      <Spinner
                                        animation="border"
                                        variant="info"
                                      />
                                    } */}
                                    </div>
                                  </>
                                ) : (
                                  <h5 className="p-3 left-m"></h5>
                                )}
                              </div>
                            ) : null}
                            {/* mobile design orders end  */}
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="nav-Issues"
                        role="tabpanel"
                        aria-labelledby="nav-Issues-tab"
                      >
                        <div className="col-12">
                          {showIssue === 1 && (
                            <div className="issue-form">
                              <h4 className="head">Product Issue Form</h4>
                              <div className="form">
                                <label className="lab">Order ID</label>
                                <br />
                                <input
                                  onChange={(e) => {
                                    setOrderId(e.target.value);
                                    setErrorOrderId("");
                                  }}
                                  className={
                                    "num " +
                                    (errororderid ? "orderid-input-error" : "")
                                  }
                                  placeholder="Order ID"
                                  value={orderid}
                                ></input>
                                {errororderid && (
                                  <p className="orderid-validation-error">
                                    {errororderid}
                                  </p>
                                )}
                                <label className="lab mt-3">Issues</label>
                                <div class="form-group">
                                  <select
                                    className={
                                      "form-control " +
                                      (errorissue ? "orderid-input-error" : "")
                                    }
                                    value={issuetype}
                                    id="exampleFormControlSelect1"
                                    onChange={(e) => {
                                      setIssueType(e.target.value);
                                      setErrorIssue("");
                                    }}
                                  >
                                    <option className="labbbbb">Issues</option>
                                    <option>Item Missing</option>
                                    <option>Late Delivery</option>
                                    <option>Product Expired</option>
                                    <option>Others</option>
                                  </select>
                                  {errorissue && (
                                    <p className="orderid-validation-error">
                                      {errorissue}
                                    </p>
                                  )}
                                </div>
                                <div className="selectmissing-items d-none">
                                  <label className="top-heading">
                                    Select Missing Item
                                  </label>
                                  <div className="tail-box">
                                    <div className="heading-text">
                                      <h5>Products</h5>
                                    </div>
                                    <div className="bottom-content">
                                      <div className="selection">
                                        <label class="check-main">
                                          <input type="checkbox" />
                                          <span class="checkmark"></span>
                                        </label>
                                        <div className="right-side">
                                          <div className="brand-img">
                                            <img
                                              src="\Assests\product.png"
                                              alt="img"
                                              className="img-fluid"
                                            />
                                          </div>
                                          <div className="brand-name">
                                            <h5>Taylors of Harrogate</h5>
                                            <h6>Yorkshire Coffee</h6>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <label className="lab mt-4">
                                  Additional Note
                                </label>
                                <br />
                                <textarea
                                  className="info2"
                                  placeholder="Describe your issue"
                                  value={subject}
                                  onChange={(e) => {
                                    setSubject(e.target.value);
                                  }}
                                ></textarea>
                                <p className="limit">0/500</p>
                              </div>
                              <div className="issue-main-btn">
                                <button
                                  type="button"
                                  class="btn sub"
                                  onClick={Addissue}
                                // data-bs-toggle="modal"
                                // data-bs-target="#exampleModal"
                                >
                                  Submit
                                </button>
                                <button
                                  type="button"
                                  class="btn sub-red"
                                  onClick={() => {
                                    setShowDetail(3);
                                    setShowIssue(null);
                                  }}
                                >
                                  Back
                                </button>
                              </div>
                            </div>
                          )}
                          {/* issue table */}
                          {isLoading ? (
                            <div className="loader-image-myaccounts">
                              <img
                                src="assets\btn-icons\2nd_v2.gif"
                                alt="Loading..."
                              />
                            </div>
                          ) : (
                            <div>
                              {showDetail === 3 && (
                                <div className="di-main table-responsive">
                                  {loader ? (
                                    <div className="text-center py-4">
                                      {/* <Spinner animation="border" variant="info" /> */}
                                    </div>
                                  ) : issueList?.length > 0 ? (
                                    <table className="table t-main">
                                      <thead className="head">
                                        <tr>
                                          <th className="th-1" scope="col">
                                            Date
                                          </th>
                                          <th className="th-1" scope="col">
                                            Order ID
                                          </th>
                                          <th className="th-1" scope="col">
                                            Issue
                                          </th>
                                          <th className="th-1" scope="col">
                                            Status
                                          </th>
                                          <th className="th-1" scope="col">
                                            Detail
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {issueList?.map((data, index) => {
                                          return (
                                            <tr key={data?.id}>
                                              <th className="tr-1" scope="row">
                                                {moment(data?.createdAt).format(
                                                  "DD-MMM-YYYY"
                                                )}
                                              </th>
                                              <td className="tr-1 t-main-order">
                                                {data?.orderId}
                                              </td>
                                              <td className="tr-1">
                                                {data?.issueType}
                                              </td>
                                              <td className="tr-1 ">
                                                <span className="co-1">
                                                  {data?.status}
                                                </span>{" "}
                                              </td>
                                              <td className="tr-1">
                                                <button
                                                  onClick={() => {
                                                    setShowIssue(2);
                                                    Editissue(data?.id);
                                                    setShowDetail(null);
                                                  }}
                                                  className="btn-2"
                                                >
                                                  Detail
                                                </button>
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </tbody>
                                    </table>
                                  ) : (
                                    <div className="blue_div">
                                      <div className="d-flex">
                                        <img
                                          src="\assets\tick.svg"
                                          className=""
                                          alt="tick"
                                        />
                                        <p className="order_para">
                                          No issues found.
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                              {showDetail === 3 && (
                                <button
                                  className="add-btn"
                                  onClick={() => {
                                    setShowIssue(1);
                                    setShowDetail(null);
                                  }}
                                >
                                  Add Issue
                                </button>
                              )}
                            </div>
                          )}
                          {/* LAST ISSUE <FORM></FORM> */}
                        </div>
                        {/* mobile design issues */}
                        {showDetail === 3 && (
                          <div>
                            {issueList?.map((data, index) => {
                              return (
                                <>
                                  <div className="mobile-issues-main">
                                    <div className="issues-detail">
                                      <p className="issues-date">Date:</p>
                                      <p className="issues-date">
                                        {" "}
                                        {moment(data?.createdAt).format(
                                          "DD-MMM-YYYY"
                                        )}
                                      </p>
                                    </div>
                                    <div className="issues-detail">
                                      <p className="issues-date">Order ID:</p>
                                      <p className="issues-date">
                                        {" "}
                                        {""}
                                        {data?.orderId}
                                      </p>
                                    </div>
                                    <div className="issues-detail">
                                      <p className="issues-date">Issues</p>
                                      <p className="issues-date">
                                        {" "}
                                        {data?.issueType}
                                      </p>
                                    </div>
                                    <div className="issues-detail">
                                      <p className="issues-date">Status</p>
                                      <p className="issues-date">
                                        {" "}
                                        {data?.status}
                                      </p>{" "}
                                    </div>
                                    <div>
                                      <button
                                        onClick={() => {
                                          setShowIssue(2);
                                          Editissue(data?.id);
                                          setShowDetail(null);
                                        }}
                                        className="detail-btn-issues"
                                      >
                                        DETAIL
                                      </button>
                                    </div>
                                    <hr className="issues-hr"></hr>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        )}
                        {/* mobile design issues end  */}
                        {showIssue === 2 && (
                          <div>
                            <div className="right-mainmm">
                              <div className=" right-1mm">
                                <div>
                                  <p className="pa-1x">Id</p>
                                </div>
                                <div>
                                  <p className="pa-2x">{issue?.id}</p>
                                </div>
                              </div>
                              <hr className="hr-1x"></hr>

                              <div className=" right-1mm">
                                <div>
                                  <p className="pa-1x">Issues</p>
                                </div>
                                <div>
                                  <p className="pa-2x">{issue?.issueType}</p>
                                </div>
                              </div>
                              <hr className="hr-1x"></hr>

                              <div className=" right-1mm">
                                <div>
                                  <p className="pa-1x">Order Date/Time</p>
                                </div>
                                <div>
                                  <p className="pa-2x">
                                    {" "}
                                    {moment(issue?.order?.createdAt).format(
                                      "DD-MMM-YYYY"
                                    )}
                                  </p>
                                </div>
                              </div>
                              <hr className="hr-1x"></hr>

                              <div className=" right-1mm">
                                <div>
                                  <p className="pa-1x">Status</p>
                                </div>
                                <div>
                                  <p className="pa-3">{issue?.status}</p>
                                </div>
                              </div>
                            </div>
                            <button
                              type="button"
                              class="btn sub-red2"
                              onClick={() => {
                                setShowDetail(3);
                                setShowIssue(null);
                              }}
                            >
                              Back
                            </button>
                          </div>
                        )}
                      </div>
                      <div
                        class="tab-pane fade"
                        id="nav-Addresses"
                        role="tabpanel"
                        aria-labelledby="nav-Addresses-tab"
                      >
                        <div className="row">
                          <div className="col-12  p-0">
                            {isLoading ? (
                              <div className="loader-image-myaccounts">
                                <img
                                  src="assets\btn-icons\2nd_v2.gif"
                                  alt="Loading..."
                                />
                              </div>
                            ) : (
                              <div className="billing_details">
                                {addressList.length > 0 && (
                                  <div>
                                    <h3 className="Billing_detailsheaging">
                                      Addresses
                                    </h3>
                                  </div>
                                )}
                                <div className="mainaddress-new mb-0">
                                  {addressList.length > 0 ? (
                                    addressList?.map((item) => {
                                      return (
                                        <>
                                          <div className="address-box">
                                            <div className="upper-content">
                                              <div className="left">
                                                <h6>{item?.name}</h6>
                                              </div>
                                              <div className="right">
                                                <img
                                                  onClick={() => {
                                                    setIsAddressEdit(true);
                                                    setAddressId(item?.id);
                                                    handleShowlocation();
                                                    getAddressTypeList();
                                                    getAddressDetails(item?.id);
                                                  }}
                                                  src="\Assests\edit-2.svg"
                                                  alt="img"
                                                  className="img-fluid right-edit"
                                                />
                                                <img
                                                  onClick={(e) => {
                                                    setAddressId(item?.id);
                                                    handleShowdelete();
                                                  }}
                                                  src="\Assests\delete-btn.svg"
                                                  alt="img"
                                                  className="img-fluid right-edit"
                                                />
                                              </div>
                                            </div>
                                            <div className="static-address">
                                              <img
                                                src="\Assests\home-2.svg"
                                                alt="img"
                                                className="img-fluid me-2"
                                              />
                                              <h6>{item?.addressType?.name}</h6>
                                            </div>
                                            <p className="address-text">
                                              {item?.address}
                                            </p>
                                            <p className="phone-text">
                                              {item?.contactNo}
                                            </p>
                                          </div>
                                        </>
                                      );
                                    })
                                  ) : loader ? (
                                    <>{/* Loader code */}</>
                                  ) : (
                                    <div className="blue_div2">
                                      <div className="d-flex">
                                        <img
                                          src="\assets\tick.svg"
                                          className=""
                                          alt="tick"
                                        />
                                        <p className="order_para">
                                          No address has been saved
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>

                                <div className="mainaddress mt-4">
                                  <button
                                    className="newaddressbtn"
                                    onClick={() => {
                                      handleShowlocation();
                                      getAddressTypeList();
                                    }}
                                  >
                                    Add New Address
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>{" "}
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="nav-Details"
                        role="tabpanel"
                        aria-labelledby="nav-Details-tab"
                      >
                        <div className="row">
                          <div className="col-12  ">
                            <div className="billing_details">
                              <div>
                                <h3 className="pa-1">Account details</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        {isLoading ? (
                          <div className="loader-image-myaccounts">
                            <img
                              src="assets\btn-icons\2nd_v2.gif"
                              alt="Loading..."
                            />
                          </div>
                        ) : (
                          <div className="row">
                            <div className="col-xl-6">
                              <div>
                                <label className="pa-2">
                                  Name<span className="spa1">*</span>
                                </label>
                                <br></br>
                                <input
                                  value={adminName}
                                  onChange={(e) => {
                                    setAdminName(e.target.value);
                                    setAdminNameError("");
                                  }}
                                  // className="inp-2"
                                  placeholder="Name"
                                  className={`inp-2 ${adminnameerror
                                    ? "error-input-accountdetail"
                                    : ""
                                    }`}
                                ></input>
                                {adminnameerror && (
                                  <p className="accountss-validations">
                                    {adminnameerror}
                                  </p>
                                )}
                              </div>
                              <div className="parentinput d-flex">
                                <div className="mblrespon">
                                  <label className="pa-2">
                                    Phone Number<span className="spa1">*</span>
                                  </label>
                                  <br></br>
                                  <input
                                    type="number"
                                    value={adminNumber}
                                    onChange={(e) => {
                                      setAdminNumber(e.target.value);
                                      setAdminNumberError("");
                                    }}
                                    // className="inp-2"
                                    placeholder="Contact Number"
                                    className={`inp-2 ${adminnumbererror
                                      ? "error-input-accountdetail"
                                      : ""
                                      }`}
                                  ></input>

                                  <div>
                                    {adminnumbererror && (
                                      <p className="accountss-validations">
                                        {adminnumbererror}
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <div>
                                  <label className="pa-2 mblrespon" style={{ marginLeft: "5px" }}>
                                    Gender
                                  </label>
                                  <br></br>
                                  <div class="dropdown">
                                    <button
                                      className="btnbtnbtn dropdown-toggle mblrespon"
                                      type="button"
                                      id="dropdownMenuButton1"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      {gender ? gender : "Select Gender"}
                                    </button>
                                    <ul
                                      class="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton1"
                                    >
                                      <li>
                                        <a
                                          class="dropdown-item"
                                          onClick={() => setGender("male")}
                                        >
                                          Male
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          class="dropdown-item"
                                          onClick={() => setGender("female")}
                                        >
                                          Female
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <label className="pa-2">
                                  Email address<span className="spa1">*</span>
                                </label>
                                <br></br>
                                <input
                                  value={adminEmail}
                                  onChange={(e) => {
                                    setAdminEmail(e.target.value);
                                    setAdminEmailError("");
                                  }}
                                  className={`inp-2 ${adminemailerror
                                    ? "error-input-accountdetail"
                                    : ""
                                    }`}
                                  placeholder="Email address"
                                ></input>
                                {adminemailerror && (
                                  <p className="accountss-validations">
                                    {adminemailerror}
                                  </p>
                                )}
                              </div>
                              <div className="parentinput">
                                {/* <div className="mblrespon">
                              <label className="pa-2 ">
                                Current password<span className="spa1">*</span>
                              </label>
                              <br></br>
                              <input
                                className="inp-1"
                                placeholder="............"
                                disabled
                              ></input>
                            </div> */}
                                <div className="mblrespon">
                                  {/* <label className="pa-2 ">Current password<span className="spa1">*</span></label><br></br> */}
                                  {/* <input className="inp-1" placeholder="............"></input> */}
                                  <div className="btn-change-main">
                                    <button
                                      className="btnchange"
                                      onClick={handleShow}
                                    >
                                      Change Password
                                    </button>
                                  </div>
                                </div>
                                {/* <div className="btnchange">
                                                            <button className="">Change Password</button>
                                                        </div> */}
                              </div>
                              <div className="save-btn-main">
                                <button
                                  onClick={SaveChanges}
                                  className="btn-2x"
                                >
                                  Save changes
                                </button>
                              </div>
                            </div>
                            <div className="col-xl-6">
                              <div className="upload-parent mt-3">
                                <h6 className="headingsss">User Image</h6>
                                <div className="upload">
                                  {profilePicture || profilePicture2 ? (
                                    <label htmlFor="upload" className="w-100">
                                      {" "}
                                      <img
                                        src={
                                          profilePicture
                                            ? URL?.createObjectURL(
                                              profilePicture
                                            )
                                            : profilePicture2
                                        }
                                        alt="img"
                                        className="img-fluid" style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                      />
                                    </label>
                                  ) : (
                                    <label htmlFor="upload">
                                      {" "}
                                      <img
                                        src="\Assests\gallery-add.svg"
                                        alt="img"
                                        className="img-fluid"
                                      />
                                      <h6 className="uploadhead">
                                        Drag your image here
                                      </h6>
                                      <p className="uploadpara">
                                        (Only *.jpeg and *.png images will be
                                        accepted)
                                      </p>
                                    </label>
                                  )}

                                  <input
                                    type="file"
                                    className="d-none"
                                    id="upload"
                                    onChange={(e) => setProfilePic(e)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title d-none" id="exampleModalLabel">
                  Modal title
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div className="modal-main">
                  <img className="img-fluid pic" src="\modal.svg"></img>
                  <h4 className="heading">Issue Submitted</h4>
                  <p className="para">
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco
                    laboris nisi ut aliquip epi ea commodo consequat.
                  </p>
                  <button
                    type="button"
                    class="btn btn-1"
                    data-bs-dismiss="modal"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        className="changepassword"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      <p>Current password</p>
      <div className="password-input-container">
        <input
          onChange={(e) => setCurrPass(e.target.value)}
          type={showPassword ? 'text' : 'password'}
          placeholder="Current password"
          className={`password-input ${currerror ? 'error-inputs-passwords' : ''} ${currentpasserror ? 'error-inputs-passwords' : ''}`}
        />
        <img
          onClick={togglePasswordVisibility}
          src="assets\btn-icons\eye.svg"
          alt=""
          className="eye-icon"
        />
      </div>
      {currerror ? <p className="password-validation-error">{currerror}</p> : null}
      {currentpasserror ? <p className="password-validation-error">{currentpasserror}</p> : null}
      
      <p>New Password</p>
      <input
        onChange={(e) => setNewPass(e.target.value)}
        type={showPassword ? 'text' : 'password'}
        placeholder="New Password"
        className={`password-input ${newwerror ? 'error-inputs-passwords' : ''} ${passwordError ? 'error-inputs-passwords' : ''}`}
      />
      {newwerror ? <p className="password-validation-error">{newwerror}</p> : null}
      
      <p>Confirm New Password</p>
      <input
        onChange={(e) => setConfirm(e.target.value)}
        type={showPassword ? 'text' : 'password'}
        placeholder="Confirm New Password"
        className={`password-input ${confirmerror ? 'error-inputs-passwords' : ''} ${passwordError ? 'error-inputs-passwords' : ''}`}
      />
      {confirmerror ? <p className="password-validation-error">{confirmerror}</p> : null}
      {passwordError ? <p className="password-validation-error">{passwordError}</p> : null}
    </Modal.Body>
        <Modal.Footer>
          <Button
            className="save"
            onClick={() => {
              ChangePassword();
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* locationmodal */}
      <Modal
        className="locationmodal"
        show={showlocation}
        onHide={handleCloselocation}
        centered
      >
        <Modal.Header closeButton>
          {isAddressEdit ? (
            <Modal.Title>Edit Address</Modal.Title>
          ) : (
            <Modal.Title>Add New Address</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <div className="locationss">
            <div className="map-div">
              <GoogleMapReact
                onClick={handleMapClick}
                bootstrapURLKeys={{
                  key: "AIzaSyAEGTG-K3yjuM5jaOVjCexGc6LdwgZwbB0",
                }}
                defaultCenter={location.center}
                defaultZoom={location.zoom}
                yesIWantToUseGoogleMapApiInternals
              >
                {clickedLocation && (
                  <Marker
                    lat={clickedLocation.lat}
                    lng={clickedLocation.lng}
                    width="20px"
                    height="20px"
                  />
                )}
              </GoogleMapReact>
            </div>
            {isLoading ? (
              <div className="loader-image-myaccounts">
                <img src="assets\btn-icons\2nd_v2.gif" alt="Loading..." />
              </div>
            ) : (
              <div className="formlocation">
                {/* <h6 className="headmodal">
                {address + " " + city + " " + state}
              </h6> */}
                {/* <h5>
                House 32, Street 55, G 10/3, Islamabad, Islamabad Capital
                Territory, Pakistan
              </h5> */}
                <p className="mt-3">
                  <span className="input-star">*</span>Full Address
                </p>
                <input
                  type="text"
                  placeholder="Enter or Pin your address!"
                 
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                    setAddressErrors("");
                    handleSearch(e);
                  }}
                  className={`maininput mb-0 ${addresserrors ? "error-input-account" : ""
                }`}
                />
                {suggestions.length > 0 && (
                  <ul className="suggestions">
                    {suggestions.map((suggestion, index) => (
                      <a>
                        {" "}
                        <li
                          key={index}
                          onClick={() => {
                            handleSuggestionClick(suggestion);
                            setSelect(true);
                          }}
                        >
                          {suggestion.description}
                        </li>
                      </a>
                    ))}
                  </ul>
                )}
                {addresserrors ? (
                  <p className="address-error-text">{addresserrors}</p>
                ) : null}
                <div className="inputss">
                  <div className="innerinputs">
                    <p>
                      <span className="input-star">*</span>City
                    </p>
                    <select
                      className="maininput"
                      value={city}
                      onChange={(e) => {
                        setCity(e.target.value);
                        // setCityErrors("");
                      }}
                    >
                      <option value="Islamabad">Islamabad</option>
                      <option value="Rawalpindi">Rawalpindi</option>
                    </select>
                    {/* {cityerrors ? (
                    <p className="address-error-text">{cityerrors}</p>
                  ) : null} */}
                  </div>
                  <div className="innerinputs">
                    <p>Nearby Place (Optional)</p>
                    <input
                      type="text"
                      placeholder="Nearby Place"
                      className="maininput"
                      value={nearBy}
                      onChange={(e) => setNearBy(e.target.value)}
                    />
                  </div>
                </div>
                {/* <div className="inputss"> */}
                {/* <div className="innerinputs">
                  <p>Postal Code</p>
                  <input
                    type="text"
                    placeholder="Postal Code"
                    className="maininput"
                    value={postalCode}
                    onChange={(e) => setPostalCode(e.target.value)}
                  />
                </div> */}
                {/* <div className="innerinputs">
                  <p>Nearby Place (Optional)</p>
                  <input
                    type="text"
                    placeholder="Nearby Place "
                    className="maininput"
                    value={nearBy}
                    onChange={(e) => setNearBy(e.target.value)}
                  />
                </div> */}
                {/* </div> */}
                <div className="inputss">
                  <div className="innerinputs">
                    <p>
                      <span className="input-star">*</span>Name
                    </p>
                    <input
                      type="text"
                      placeholder="Name"
                      className={`maininput ${nameerrors ? "error-input-account" : ""
                        }`}
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        setNameErrors("");
                      }}
                    />
                    {nameerrors ? (
                      <p className="address-error-text">{nameerrors}</p>
                    ) : null}
                  </div>
                  <div className="innerinputs">
                    <p>
                      <span className="input-star">*</span>Mobile Number
                    </p>
                    <input
                      type="number"
                      placeholder="Mobile Number"
                      className={`maininput ${mobileerrors ? "error-input-account" : ""
                        }`}
                      value={mobileNumber}
                      onChange={(e) => {
                        setMobileNumber(e.target.value);
                        setMobileErrors("");
                      }}

                    />
                    {mobileerrors ? (
                      <p className="address-error-text">{mobileerrors}</p>
                    ) : null}
                  </div>
                </div>
                <div className="spanbtns">
                  {addressTypeList.length > 0
                    ? addressTypeList?.map((item) => {
                      return (
                        <>
                          <span
                            className={
                              activeState == item?.id ? "spanactive" : ""
                            }
                            onClick={() => {
                              setActiveState(item?.id);
                              setAddressTypeId(item?.id);
                            }}
                          >
                            <img
                              src={item?.iconUrl}
                              alt="img"
                              className="spanicon"
                            />
                            {item?.name}
                          </span>
                        </>
                      );
                    })
                    : null}
                </div>
              </div>
            )}
            <button
              className="savebtn"
              disabled={loader}
              onClick={isAddressEdit ? editAddress : addNewAddress}
            >
              Save Address
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {/* deletemodal */}
      <Modal
        className="deletemodal"
        show={showdelete}
        onHide={handleClosedelete}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete address ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>
            Are you sure you want to delete <br /> this address?
          </h4>
          <div className="deletebtns">
            <button
              className="dark"
              onClick={() => {
                deleteAddress();
                handleClosedelete();
              }}
            >
              Confirm
            </button>
            <button className="light" onClick={handleClosedelete}>
              cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="bankreceipt"
        show={showreciept}
        onHide={handleCloseRec}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="reciept-img">
            <img src={receiptImg} alt="img" className="img-fluid" />
          </div>
        </Modal.Body>
      </Modal>

      {/* modal for upload image */}

      <Modal
        className="bankreceipt"
        show={uploadreciept}
        onHide={showUploadModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Please upload your image</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "10px" }}>
          <div className="upload-img">
            <label htmlFor="uploadsss">
              {uploadimage ? (
                <img
                  className="img-fluid upload-img-set"
                  src={uploadimage ? URL?.createObjectURL(uploadimage) : ""}
                />
              ) : (
                <img
                  src="\Assests\Uploadcart.svg"
                  alt="img"
                  className="img-fluid ml-2"
                />
              )}
            </label>
            <h6>Upload transaction screenshot</h6>
            <input
              type="file"
              name={uploadimage}
              multiple
              accept="image/*"
              onChange={UploadImageChange}
              className="d-none"
              id="uploadsss"
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer-footer">
          <div className="orderbtn">
            <button onClick={placeOrder}>Upload</button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Account;
